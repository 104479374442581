// BIBLIOTECAS

import $ from 'jquery';

window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/autocomplete';
//add as many widget as you may need

// SELECIONA A GUIA ABERTA CORRETAMENTE do admin
require('./bootstrap');

require('alpinejs');

require('./inputmask.js')

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
